
import authReducer from "./Reducers/authReducer";
import categoryReducer from "./Reducers/categoryReducer";
import productReducer from "./Reducers/productReducer";
import cardReducer from "./Reducers/cardReducer";
import contactUsReducer from "./Reducers/contactUsReducer";
const rootReducer =  {
    auth: authReducer,
    category: categoryReducer,
    card: cardReducer,
    product: productReducer,
    contactus: contactUsReducer,
}
export default rootReducer;  