import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { messageClear as cardMessageClear } from "./cardReducer"; // Renamed import
import api from "../../api/api";

// Async thunk for submitting contact form
export const submit = createAsyncThunk(
    '/contactus/submit',
    async (info, { rejectWithValue }) => {
        try {
            const { data } = await api.post('/contact-us/submit', info);
            return data; // No need for fulfillWithValue
        } catch (error) {
            return rejectWithValue(error.response?.data || "An error occurred");
        }
    }
);

// Reducer slice for contact form
export const contactUsReducer = createSlice({
    name: 'contactus',
    initialState: {
        successMessage: '',
        errorMessage: '',
        loader: false,
    },
    reducers: {
        messageClear: (state) => {
            state.errorMessage = "";
            state.successMessage = "";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(submit.pending, (state) => {
                state.loader = true;
            })
            .addCase(submit.rejected, (state, { payload }) => {
                state.loader = false;
                state.errorMessage = payload;
            })
            .addCase(submit.fulfilled, (state, { payload }) => {
                state.loader = false;
                state.successMessage = payload.message;
            });
    }
});

export default contactUsReducer.reducer;
export const { messageClear } = contactUsReducer.actions;
